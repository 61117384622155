/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getAdminToolResources(){
    const data = {
        type: 'adminTools',
        target: 'getAdminToolResources',
        authToken: getToken(),
    }
    return await getResponse(data);
}

export async function migrateCompanyResources(sendingCompany, receivingCompany, migrateType){
    const data = {
        type: 'adminTools',
        target: 'migrateCompanyResources',
        authToken: getToken(),
        sendingCompany,
        receivingCompany,
        migrateType
    }

    return await getResponse(data);
}

export async function getCompanyEmployees(companyIdentifier){
    const data = {
        type: 'adminTools',
        target: 'getCompanyEmployees',
        authToken: getToken(),
        companyIdentifier
    }

    return await getResponse(data);
}

export async function migrateCsaResources(sendingCsa, receivingCsa, migrateType){
    const data = {
        type: 'adminTools',
        target: 'migrateCsaResources',
        authToken: getToken(),
        sendingCsa,
        receivingCsa,
        migrateType
    }

    return await getResponse(data);
}
export async function migrateCsa(sendingCsa, receivingCompany){
    const data = {
        type: 'adminTools',
        target: 'migrateCsa',
        authToken: getToken(),
        sendingCsa,
        receivingCompany
    }

    return await getResponse(data);
}

export async function deleteAuthenticationTokens(date){
    const data = {
        type: 'adminTools',
        target: 'deleteAuthenticationTokens',
        authToken: getToken(),
        date
    }

    return await getResponse(data);
}

export async function addScheduleItemsToRouteTable(){
    const data = {
        type: 'adminTools',
        target: 'addScheduleItemsToRouteTable',
        authToken: getToken(),
    }

    return await getResponse(data);
}
export async function migrateDocumentRequests() {
    const data = {
        type: 'adminTools',
        target: 'migrateDocumentRequests',
        authToken: getToken()
    }

    return await getResponse(data);
}

export async function getTc2ApiLogs(filters, maxUid) {
    const data = {
        type: 'adminTools',
        target: 'getTc2ApiLogs',
        authToken: getToken(),
        filters: filters,
        maxUid: maxUid,
    }

    return await getResponse(data);
}

export async function getToApiLogs(filters, maxUid) {
    const data = {
        type: 'adminTools',
        target: 'getToApiLogs',
        authToken: getToken(),
        filters: filters,
        maxUid: maxUid,
    }

    return await getResponse(data);
}

export async function getTcApiLogs(filters, maxUid) {
    const data = {
        type: 'adminTools',
        target: 'getTcApiLogs',
        authToken: getToken(),
        filters: filters,
        maxUid: maxUid,
    }

    return await getResponse(data);
}

export async function getMtrApiLogs(filters, maxUid) {
    const data = {
        type: 'adminTools',
        target: 'getMtrApiLogs',
        authToken: getToken(),
        filters: filters,
        maxUid: maxUid,
    }

    return await getResponse(data);
}


export async function getLhApiLogs(filters, maxUid) {
    const data = {
        type: 'adminTools',
        target: 'getLhApiLogs',
        authToken: getToken(),
        filters: filters,
        maxUid: maxUid,
    }

    return await getResponse(data);
}

export async function submitPtoTransactions(amount, csas, titles){
    const data = {
        type: 'adminTools',
        target: 'submitPtoTransactions',
        authToken: getToken(),
        amount,
        csas,
        titles
    }

    return await getResponse(data);
}

export async function getConferenceSignups(){
    const data = {
        type: 'adminTools',
        target: 'getConferenceSignups',
        authToken: getToken(),
    }
    
    return await getResponse(data);
}