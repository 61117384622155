/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { Button, Dropdown, Form, ListGroup, Modal, Spinner } from "react-bootstrap";
import QuickTable from "../../../components/QuickTable";
import { useState } from "react";
import KeyValueRow from "../../../components/KeyValueRow";
import CustomButton from "../../../components/CustomButton";
import { deleteEDDocument, downloadDlOrSsn, downloadEDDocument, sendBackDlOrSsn, sendBackEdDocument, sendDocumentRequestInvitation } from "../../../services/HRServices/HRDocumentsService";
import { AppGlobals } from "../../../App";
import AlertModal from "../../../components/AlertModals/AlertModal";
import { OnboardingDocumentsReadOnly, downloadBase64, taxDocumentDictionary, DirectDepositDocuments } from "../../../tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import EDDocumentPreview from "./EDDocumentPreview";
import OldAttachmentPreview from './OldAttachmentPreview';

export default function DocumentRequestDetails({documentRequest, handleRemoveEdDocument, handleSetEDDocumentIncomplete}) {
    const [showPreview, setShowPreview] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [edDocToDelete, setEdDocToDelete] = useState(undefined);
    const [docToSendBack, setDocToSendBack] = useState(undefined);
    const [showSendBackModal, setShowSendBackModal] = useState(false);
    const [isSendingBack, setIsSendingBack] = useState(false);
    const [revisionMessage, setRevisionMessage] = useState('');
    const [isDownloading, setIsDownloading] = useState(undefined);
    const [isPreviewing, setIsPreviewing] = useState(undefined);
    const [isPreviewingCustom, setIsPreviewingCustom] = useState(undefined);
    const [isDownloadingCustom, setIsDownloadingCustom] = useState(undefined)
    const [isDownloadingSsnDl, setIsDownloadingSsnDl] = useState(undefined)
    const [docToPreview, setDocToPreview] = useState(undefined);
    const [selectedAttachment, setSelectedAttachment] = useState(undefined);
    const [showAttachment, setShowAttachment] = useState(false);

    const handleSendInvitation = async (sendTo) => {
        setIsSending(true);

        const response = await sendDocumentRequestInvitation(documentRequest.uid, sendTo);
        if (response.status == '200') {
            AppGlobals.alert('success', 'Sent Document Request Invitation');
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSending(false);
    }

    const handleSendBackDocument = async () => {
        setIsSendingBack(true);
        if(docToSendBack === 'ssn' || docToSendBack === 'dl'){
            // document is of type ssn or dl api call
            const response = await sendBackDlOrSsn(documentRequest.uid, docToSendBack, revisionMessage);
            if(response.status === '200'){
                if(docToSendBack === 'dl'){
                    documentRequest.dlDataIdentifier = null;
                }else{
                    documentRequest.ssnDataIdentifier = null;
    
                }
                setRevisionMessage('');
                setShowSendBackModal(false);
            }else{
                AppGlobals.alert('danger', response.message)
            }
        } else{
            // any other document type api call
            let response;
            if(typeof docToSendBack === 'number'){
                response = await sendBackEdDocument(documentRequest.uid, 'custom', revisionMessage, docToSendBack);
            }else{
                response = await sendBackEdDocument(documentRequest.uid, docToSendBack, revisionMessage);
            }
            if (response.status == '200') {
                handleSetEDDocumentIncomplete(documentRequest.uid, docToSendBack);
                setRevisionMessage('');
                setShowSendBackModal(false);
            }
        }
        setIsSendingBack(false);
    }

    const handleDownloadDocument = async (documentType) => {
        setIsDownloading(documentType);
        if (documentRequest.documents[documentType].base64) {
            downloadBase64(documentRequest.documents[documentType].base64, `${documentRequest.employee?.firstName} ${documentRequest.employee?.lastName} ${OnboardingDocumentsReadOnly[documentType]}`);
        } else {
            const response = await downloadEDDocument(documentRequest.documents[documentType].uid, documentRequest.documents[documentType].archived ? 'archivedDocument' : documentType);
            if (response.status == '200') {
                documentRequest.documents[documentType].base64 = response.document;
                downloadBase64(response.document, `${documentRequest.employee?.firstName} ${documentRequest.employee?.lastName} ${OnboardingDocumentsReadOnly[documentType]}`);
            }
        }
        setIsDownloading(null);
    }

    async function handleDownloadSsnOrDl(documentType, uid){
        setIsDownloadingSsnDl(documentType);
        const type = documentType === 'ssn' ? 'Social Security Card' : 'Driver\'s License';
        const response = await downloadDlOrSsn(uid);
        if(response.status == 200){
            downloadBase64(response.base64, `${documentRequest.employee?.firstName} ${documentRequest.employee?.lastName} ${type}`);
        } else{
            AppGlobals.alert('danger', response.message)
        }
        setIsDownloadingSsnDl(undefined);
    }

    const handlePreviewDocument = async (documentType) => {
        setIsPreviewing(documentType);
        if (documentRequest.documents[documentType].base64) {
            setDocToPreview(documentRequest.documents[documentType]);
            setShowPreview(true);
        } else {
            const response = await downloadEDDocument(documentRequest.documents[documentType].uid, documentRequest.documents[documentType].archived ? 'archivedDocument' : documentType);
            if (response.status == '200') {
                documentRequest.documents[documentType].base64 = response.document;
                setDocToPreview(documentRequest.documents[documentType]);
                setShowPreview(true);
            }
        }
        setIsPreviewing(null);
    }

    async function handleDownloadCustomDocument(doc){
        if (doc.base64) {
            downloadBase64(doc.base64, `${documentRequest.employee?.firstName} ${documentRequest.employee?.lastName} - ${doc.documentInfo.name}`);
        } else {
            setIsDownloadingCustom(doc.uid);

            // FIX
            const response = await downloadEDDocument(doc.uid, 'custom');
            if(response.status == 200){
                doc.base64 = response.document;
                downloadBase64(response.document, `${documentRequest.employee?.firstName} ${documentRequest.employee?.lastName} - ${doc.documentInfo.name}`);
            }else{
                AppGlobals.alert('danger', response.message)
            }
            setIsDownloadingCustom(null)
        }
    }

    async function handlePreviewCustomDocument(doc){
        if(doc.base64){
            setDocToPreview(doc);
            setShowPreview(true);
        } else{
            setIsPreviewingCustom(doc.uid);
            const response = await downloadEDDocument(doc.uid, 'custom');
            if(response.status == 200){
                doc.base64 = response.document;
                setDocToPreview(doc);
                setShowPreview(true);
            }else{
                AppGlobals.alert('danger', response.message)
            }
            setIsPreviewingCustom(null);
        }
    }

    async function handlePreviewDlSsn(documentType){
        setIsDownloading(documentType);
        const documentIdentifier =  documentType === 'ssn' ? documentRequest.ssnDataIdentifier : documentRequest.dlDataIdentifier;
        const response = await downloadDlOrSsn(documentIdentifier);
        if(response.status === '200'){
            const title = documentType === 'dl' ? `${documentRequest.employee.firstName} ${documentRequest.employee.lastName} Driver's License` : `${documentRequest.employee.firstName} ${documentRequest.employee.lastName} Social Security Card`;
            setSelectedAttachment({base64: response.base64, title})
            setShowAttachment(true);
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsDownloading(null)
    }

    const handleDeleteEDDocument = async () => {
        let response;
        if(typeof edDocToDelete === 'number'){
            response = await deleteEDDocument(edDocToDelete, 'custom');
        }else{
            response = await deleteEDDocument(documentRequest.documents[edDocToDelete].uid, documentRequest.documents[edDocToDelete].archived ? 'archivedDocument' : edDocToDelete);
        }
        if (response.status == '200') {
            handleRemoveEdDocument(documentRequest.uid, edDocToDelete);
            return true;
        }
        return false;
    }

    const sortedDocuments = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), ...Object.keys(DirectDepositDocuments), 'f8850'].filter(d => Object.keys(documentRequest.documents).includes(d));

    const edDocumentElements = sortedDocuments.filter(dType => !documentRequest.documents[dType].archived).map((documentType) => {
        const doc = documentRequest.documents[documentType];

        return (
            <tr key={documentType}>
                <td>{OnboardingDocumentsReadOnly[documentType]}</td>
                <td style={{color: !doc.employeeCompleted ? 'red' : undefined}}>{doc.employeeCompleted ? moment(doc.employeeCompleted).format('MMM D, YYYY') : 'Awaiting Completion'}</td>
                <td style={{color: 'employerCompleted' in doc && !doc.employerCompleted ? 'red' : undefined}}>
                    { 'employerCompleted' in doc ? doc.employerCompleted ? 
                        moment(doc.employerCompleted).format('MMM D, YYYY') : 'Awaiting Completion' : ''
                    }
                </td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { (doc.employeeCompleted || doc.archived) && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={documentType == isPreviewing} onClick={() => handlePreviewDocument(documentType)}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={documentType == isDownloading} onClick={() => handleDownloadDocument(documentType)}/>
                                { !doc.archived && 
                                    <Button variant='danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack(documentType); setShowSendBackModal(true)}}>
                                        <FontAwesomeIcon icon={faThumbsDown}/>
                                    </Button>
                                }
                            </>
                        }
                        <Button title="Delete" variant='danger' style={{padding: '0px 6px 0px 6px'}} onClick={() => {setEdDocToDelete(documentType)}}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </Button>
                    </div>
                </td>
            </tr>
        )
    });

    const customDocumentElements = documentRequest.documents.custom.map((doc) => {
        return (
            <tr key={`custom${doc.uid}`}>
                <td>{doc.documentInfo.name}</td>
                <td style={{color: !doc.employeeCompleted ? 'red' : undefined}}>{doc.employeeCompleted ? moment(doc.employeeCompleted).format('MMM D, YYYY') : 'Awaiting Completion'}</td>
                <td style={{color: 'employerCompleted' in doc && !doc.employerCompleted ? 'red' : undefined}}>
                    { 'employerCompleted' in doc ? doc.employerCompleted ? 
                        moment(doc.employerCompleted).format('MMM D, YYYY') : 'Awaiting Completion' : ''
                    }
                </td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { doc.employeeCompleted && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={isPreviewingCustom === doc.uid} onClick={() => handlePreviewCustomDocument(doc)}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={isDownloadingCustom === doc.uid} onClick={() => handleDownloadCustomDocument(doc)}/>
                                <Button variant='danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack(doc.uid); setShowSendBackModal(true)}}>
                                    <FontAwesomeIcon icon={faThumbsDown}/>
                                </Button>
                            </>
                        }
                        <Button title="Delete" variant='danger' style={{padding: '0px 6px 0px 6px'}} onClick={() => {setEdDocToDelete(doc.uid)}}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </Button>
                    </div>
                </td>
            </tr>
        )
    })

    const archivedElements = sortedDocuments.filter(dType => documentRequest.documents[dType].archived).map((documentType) => {
        const doc = documentRequest.documents[documentType];

        return (
            <tr key={documentType}>
                <td>{OnboardingDocumentsReadOnly[documentType]}</td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { (doc.employeeCompleted || doc.archived) && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={documentType == isPreviewing} onClick={() => handlePreviewDocument(documentType)}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={documentType == isDownloading} onClick={() => handleDownloadDocument(documentType)}/>
                            </>
                        }
                        <Button title="Delete" variant='danger' style={{padding: '0px 6px 0px 6px'}} onClick={() => {setEdDocToDelete(documentType)}}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </Button>
                    </div>
                </td>
            </tr>
        )
    })

    const dlAndSsnElements = (
        <>
            <tr>
                <td>
                    Driver's License
                </td>
                <td style={{color: documentRequest.dlDataIdentifier ? 'var(--bs-success)' : 'red'}}>
                    {documentRequest.dlDataIdentifier ? 'Completed' : 'Awaiting Completion'}
                </td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { documentRequest.dlDataIdentifier && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={isDownloading == 'dl'} onClick={() => {handlePreviewDlSsn('dl')}}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={isDownloadingSsnDl == 'dl'} onClick={() => handleDownloadSsnOrDl('dl', documentRequest.dlDataIdentifier)} />
                                <Button variant='danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack('dl'); setShowSendBackModal(true)}}>
                                    <FontAwesomeIcon icon={faThumbsDown}/>
                                </Button>
                            </>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Social Security Card
                </td>
                <td style={{color: documentRequest.ssnDataIdentifier ? 'var(--bs-success)' : 'red'}}>
                    {documentRequest.ssnDataIdentifier ? 'Completed' : 'Awaiting Completion'}
                </td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { documentRequest.ssnDataIdentifier && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={isDownloading == 'ssn'} onClick={() => {handlePreviewDlSsn('ssn')}}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={isDownloadingSsnDl == 'ssn'} onClick={() => handleDownloadSsnOrDl('ssn', documentRequest.ssnDataIdentifier)} />
                                <Button variant='danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack('ssn'); setShowSendBackModal(true)}}>
                                    <FontAwesomeIcon icon={faThumbsDown}/>
                                </Button>
                            </>
                        }
                    </div>
                </td>
            </tr>
        </>
    )

    const activityElements = documentRequest.activity.map((item) => {
        return (
            <KeyValueRow isListItem key={item.uid} title={item.isEmployer ? 'Employer' : 'Employee'} value={moment(item.dateTime).format('MMM D, YYYY [at] h:mm A')}/>
        )
    });

    const oldAttachmentsElements = documentRequest.oldAttachments.map((oa) => {
        return (
            <ListGroup.Item key={oa.uid} action onClick={() => {setSelectedAttachment(oa); setShowAttachment(true);}}>{oa.title}</ListGroup.Item>
        )
    })

    let employerSignerMiddleName;
    let employerName;
    if(documentRequest.employerSigner){
        employerSignerMiddleName = documentRequest.employerSigner.middleName ? ` ${documentRequest.employerSigner?.middleName}` : '';
        employerName = `${documentRequest.employerSigner.firstName}${employerSignerMiddleName} ${documentRequest.employerSigner.lastName}`
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Document Request</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                    {documentRequest.employerSigner ? <h5><b>Employer Signer: </b>{employerName}</h5> : <div></div>}
                    <Dropdown style={{alignSelf: 'flex-end', marginBottom: 12}}>
                        <Dropdown.Toggle disabled={isSending}>
                            { isSending ? <Spinner size='sm'/> : 
                                <span>Resend Invitation</span>
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {handleSendInvitation('employee')}}>Send To Employee</Dropdown.Item>
                            <Dropdown.Item onClick={() => {handleSendInvitation('employer')}}>Send To Employer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                { edDocumentElements.length > 0 && 
                    <QuickTable title='Documents' headers={['Document Type', 'Employee Completed', 'Employer Completed', 'Actions']} rows={[...edDocumentElements, ...customDocumentElements]} widths={[null, null, null, 1]} size='sm'/>
                }
                { ('i9' in documentRequest.documents) && 
                    <QuickTable title={`Driver's License and Social Security Card`} headers={['Title', 'Employee Completed', 'Actions']} rows={dlAndSsnElements} widths={[null, null, 1]} size='sm'/>
                }
                { archivedElements.length > 0 && 
                    <QuickTable title='Archived Documents' headers={['Document Type', 'Actions']} rows={archivedElements} widths={[null, 1]} size='sm'/>
                }
                { oldAttachmentsElements.length > 0 && 
                    <>
                        <h5>Archived Attachments</h5>
                        <ListGroup style={{marginBottom: 12}}>
                            {oldAttachmentsElements}
                        </ListGroup>
                    </>
                }
                <h5>Request View Activity</h5>
                <ListGroup>
                    { activityElements.length > 0 ?
                        activityElements
                    :
                        <ListGroup.Item>None</ListGroup.Item>
                    }
                </ListGroup>
            </Modal.Body>
            <AlertModal centered show={edDocToDelete} onHide={() => {setEdDocToDelete(undefined)}} title='Permanently Delete Document' message='This cannot be undone.' buttonLabel='Delete Document' callBack={handleDeleteEDDocument}/>
            <Modal show={showSendBackModal} onHide={() => {setShowSendBackModal(false)}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Send Document Back to Employee to Revise?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Include a message below for the employee with instructions on what needs revised.</span>
                    <Form.Control value={revisionMessage} onChange={(event) => {setRevisionMessage(event.target.value)}} as='textarea' rows={3}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton label='Request Revision' isLoading={isSendingBack} onClick={handleSendBackDocument}/>
                </Modal.Footer>
            </Modal>
            <Modal show={showPreview} onHide={() => {setShowPreview(false)}} fullscreen>
                <EDDocumentPreview document={docToPreview}/>
            </Modal>
            <Modal show={showAttachment} onHide={() => {setShowAttachment(false)}} fullscreen>
                <OldAttachmentPreview document={selectedAttachment}/>
            </Modal>
        </>
    )
}